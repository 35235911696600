<template>
  <div>
    <div>
      <span style='font-weight: bold' :class='[options && options.class || "color-primary"]'>{{ compCustomLabel }}</span>
    </div>
    <v-tooltip bottom :disabled='!options || !options.tooltip'>
      <template #activator='{ on, attrs }'>
        <v-img
          v-if='options && (options.type === "image" || options.type === "img")'
          lazy
          :src='getImageSrc(value)'
          :width='options.width'
          hide-details
          style='object-fit: contain !important'
          v-bind='attrs'
          v-on='on'
        />
        <span v-else style='white-space: pre-wrap' v-bind='attrs' v-on='on'>{{ value }} </span>
      </template>
      <span> {{ options && options.tooltip }}</span>
    </v-tooltip>
  </div>
</template>

<script>
  export default {
    name: 'TitleValue',
    props: {
      value: {
        type: String | Number, // eslint-disable-line
        default: '', // eslint-disable-line
      },
      customLabel: {
        type: String,
        default: '',
      },
      options: {
        type: Object,
        default: () => {},
      },
    },
    computed: {
      compCustomLabel: function () {
        return `${this.customLabel.charAt(0).toUpperCase()}${this.customLabel.substring(1, this.customLabel.length)}`;
      },
    },
    methods: {
      getImageSrc: function (imgBase64) {
        return `data:image/png;base64, ${imgBase64}`;
      },
    },
  };
</script>
